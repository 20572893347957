<template>
  <div
    ref="navbarRef"
    class="navbar-custom"
    :class="{
      transparent_backgroundcolor: isBackgroundTransparent,
      open_dropdown: collapse,
    }"
  >
    <div class="container navbar__container">
      <div class="row row-navbar__uncollapse">
        <div class="d-flex navbar__content align-items-center">
          <div class="navbar__content__logo">
            <NuxtLink :to="localePath('/')" style="height: 100%">
              <VIcon name="iconLogo" width="80px" :color="getLogoColor" height="40px" />
            </NuxtLink>
            <div v-if="route.name?.toString().startsWith('blog')" class="navbar__content__logo__blog">
              <NuxtLink :to="localePath('/blog')" class="d-flex">
                <span class="bar" :style="`border-color: ${getBarColor}`"></span>
                <span class="text">Blog</span>
              </NuxtLink>
            </div>
          </div>
          <div class="navbar__content__vagas">
            <VButton
              v-if="i18n.locale == 'pt'"
              class="btn-vagas"
              to="https://www.linkedin.com/jobs/search/?f_C=1324820&geoId=92000000&sortBy=DD"
              :color="getVagaColor"
              >{{ $t('message.navbar.beASis.title') }}</VButton
            >
          </div>
          <div class="navbar__content__hamburguer">
            <div style="display: flex; align-items: center" @click="collapse = !collapse">
              <VIcon :name="collapse ? 'iconMenuClose' : 'iconMenu'" color="#FFB500" width="40px" height="22px" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="row row-navbar__collapse"
        :class="{ open_collapse: collapse, close_collapse: !collapse }"
        style="width: 100%"
      >
        <div class="navbar__collapse">
          <p class="navbar__collapse__text" v-html="$t('message.navbar.sisResume')"></p>
          <div class="collapse__itens">
            <div class="collapse__itens__cards">
              <ul class="collapse__itens__cards__list">
                <li v-for="(card, index) in cards" :key="index" class="collapse__itens__cards__list__item">
                  <NuxtImg
                    class="collapse__itens__cards__list__item__image"
                    :src="`/images/menu/${card.image}`"
                    loading="lazy"
                    quality="100"
                    width="260px"
                    height="230px"
                    alt="card image"
                    sizes="xs:260px sm:260px md:260px lg:260px xl:260px"
                  />
                  <div class="collapse__itens__cards__list__item__card">
                    <h4 class="collapse__itens__cards__list__item__card__title font-bold">
                      {{ $t(card.title) }}
                    </h4>
                    <VButton
                      :to="{
                        path: card.link,
                        query: { scrollTo: card.scrollTo },
                      }"
                      class="collapse__itens__cards__list__item__card__button"
                      color="secondary"
                    >
                      {{ $t(card.buttonText) }}
                    </VButton>
                  </div>
                </li>
              </ul>
            </div>

            <div class="collapse__itens__options">
              <div class="collapse__itens__options__list">
                <div v-for="(link, index) in getMenuItens" :key="index" class="collapse__itens__options__list__link">
                  <NuxtLink v-slot="{ href, navigate, isActive }" :to="localePath(link.link)" custom>
                    <a :class="{ 'router-link-active': isActive }" :href="href" @click="wrapNavigate(navigate, $event)"
                      >{{ $t(link.title) }}
                    </a>
                  </NuxtLink>
                </div>
              </div>

              <div class="collapse__itens__options__vagas">
                <VButton
                  v-if="i18n.locale == 'pt'"
                  to="https://www.linkedin.com/jobs/search/?f_C=1324820&geoId=92000000&sortBy=DD"
                  class="btn-vagas"
                  color="secondary"
                >
                  {{ $t('message.navbar.beASis.title') }}
                </VButton>
              </div>
              <ul class="collapse__itens__options__lang">
                <li>
                  <VIcon
                    name="iconLanguage"
                    color="#FFB500"
                    width="22px"
                    height="22px"
                    style="margin: 0px 8px 0px 0px"
                  />
                </li>
                <li
                  v-for="(lang, index) in langs"
                  :key="index"
                  class="collapse__itens__options__lang__option"
                  :class="lang.opt == i18n.locale ? 'collapse__itens__options__lang__option--selected' : ''"
                  @click="changeLocale(lang.opt)"
                >
                  {{ lang.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '#imports'

const route = useRoute()
const collapse = ref(false)
const scrollPosition = ref(0)
const i18n = ref(useI18n())
const localePath = useLocalePath()
const langs = ref([
  { name: 'Português', opt: 'pt' },
  { name: 'English', opt: 'en' },
  { name: 'Español', opt: 'es' },
])
const cards = ref([
  {
    title: 'message.navbar.cards.first.title',
    link: '/o-que-fazemos',
    scrollTo: 'transformation',
    image: 'transformation.webp',
    buttonText: 'message.navbar.cards.first.buttonText',
  },
  {
    title: 'message.navbar.cards.second.title',
    link: '/o-que-fazemos',
    scrollTo: 'services',
    image: 'services.webp',
    buttonText: 'message.navbar.cards.second.buttonText',
  },
])
const links = ref([
  {
    title: 'message.navbar.products.title',
    link: '/o-que-fazemos',
  },
  {
    title: 'message.navbar.weAreSis.title',
    link: '/quem-somos',
  },
  {
    title: 'message.navbar.weAreSis.itens.clients',
    link: '/clientes-e-parceiros',
  },
  {
    title: 'message.navbar.weAreSis.itens.successCases',
    link: '/casos-de-sucesso',
  },
  {
    title: 'message.navbar.blog.title',
    link: '/blog',
  },
  {
    title: 'message.navbar.contact.title',
    link: '/contato',
  },
  {
    title: 'message.navbar.midia.title',
    link: '/midia',
  },
  {
    title: 'message.navbar.career.title',
    link: '/carreiras',
  },
])
const navbarRef = ref()

useDetectOutsideClick(navbarRef, () => {
  collapse.value = false
})

watch(
  () => route.fullPath,
  () => {
    collapse.value = false
  },
)

function updateScroll() {
  scrollPosition.value = window.scrollY
}
function changeLocale(locale: string) {
  localStorage.setItem('language', locale)
  i18n.value.setLocale(locale)
}
function wrapNavigate(navigate: any, event: any) {
  collapse.value = false
  navigate(event)
}

const isBackgroundTransparent = computed(() => {
  if (scrollPosition.value > 50) return false
  if (
    route.path?.startsWith('termos-e-condicoes-de-uso') == true ||
    route.path?.startsWith('codigo-de-etica-e-conduta') == true ||
    route.path?.startsWith('politica-de-privacidade') == true
  ) {
    return false
  }
  return true
})
const getMenuItens = computed(() => {
  const itens = links.value.filter((item) => {
    if (item.title == 'message.navbar.blog.title' && i18n.value.locale != 'pt') return false
    if (item.title == 'message.navbar.midia.title' && i18n.value.locale != 'pt') return false
    if (item.title == 'message.navbar.career.title' && i18n.value.locale != 'pt') return false
    if (item.title == 'message.navbar.weAreSis.itens.successCases' && i18n.value.locale != 'pt') return false

    return true
  })
  return itens
})
const getLogoColor = computed(() => {
  if (collapse.value) return 'white'
  if (isBackgroundTransparent.value == false) return 'white'

  if (route.name == 'blog-title___pt' || route.name?.toString().startsWith('blog-todos')) {
    return 'black'
  }

  return 'white'
})
const getBarColor = computed(() => {
  if (collapse.value) return 'white'
  if (isBackgroundTransparent.value == false) return 'white'

  if (route.name == 'blog-title___pt' || route.name?.toString().startsWith('blog-todos')) {
    return '#323232'
  }

  return 'white'
})
const getVagaColor = computed(() => {
  if (collapse.value) return 'fifth'
  if (isBackgroundTransparent.value == false) return 'fifth'

  if (route.name == 'blog-title___pt' || route.name?.toString().startsWith('blog-todos')) {
    return 'third'
  }

  return 'fifth'
})

onMounted(() => {
  window.addEventListener('scroll', updateScroll)
})
</script>

<style lang="scss" scoped>
.btn-vagas {
  animation: 1s in-out forwards;
  padding: 10px 20px;
  letter-spacing: 0px;
  letter-spacing: 0.65px;
  height: 40px;
}

.btn-vagas:hover {
  font-weight: bold;
}

@keyframes animateThis {
  0% {
    width: 150px;
  }

  100% {
    width: 200px;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(-500px);
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  0% {
    transform: translateY(0);
  }

  20% {
  }
  100% {
    transform: translateY(-1000px);
  }
}

.transparent_backgroundcolor {
  background: #1f214a00 !important;
  -webkit-transition: background-color 1s 0s ease;
  -ms-transition: background-color 1s 0s ease;
  transition: background-color 1s 0s ease;
}

.open_dropdown {
  background: #1f214a 0% 0% no-repeat padding-box !important;
  -webkit-transition: background-color 1s 0s ease;
  -ms-transition: background-color 1s 0s ease;
  transition: background-color 1s 0s ease;
}

.open_collapse {
  animation: slide-in 0.3s both;
}

.close_collapse {
  display: none;
  // animation: slide-out 0.5s both;
}

.navbar-custom {
  position: fixed;
  background: rgba(31, 33, 74, 0.8705882352941177) 0 0 no-repeat padding-box;
  width: 100%;
  z-index: 999;
  height: auto;
  overflow: hidden;
  transition: all 1s cubic-bezier(0.09, 1.04, 0.3, 0.97);
  padding: 0px;
}

.navbar__content {
  width: 100%;
  height: 70px;
}

.navbar__content__logo {
  flex-grow: 1;
  display: flex;
  height: 40px;
  &__blog {
    flex-grow: 1;
    display: flex;
    align-items: center;

    a {
      @media screen and (max-width: $mobile) {
        align-items: center;
        justify-content: center;
      }
    }

    .bar {
      height: 40px;
      border: 1px solid #ffffff;
      margin-left: 15px;
      @media screen and (max-width: $mobile) {
        margin-left: 10px;
        height: 36px;
      }
    }

    .text {
      font: normal normal 900 26px/32px Montserrat-Black;
      color: #00b4ff;
      margin-left: 15px;

      @media screen and (max-width: $mobile) {
        font: normal normal 900 21px/34px Montserrat-Black;
        margin-left: 10px;
        margin-top: -7px;
      }
    }
  }
}

.navbar__content__hamburguer {
  display: flex;
  justify-items: center;
  align-items: center;
  cursor: pointer;
}

.navbar__content__vagas {
  width: 300px;
  display: flex;
  justify-content: center;
}

.navbar__collapse {
  color: white;
}

.navbar__collapse__text {
  width: 60%;
  color: #fff;
  font: normal normal normal 16px/24px Montserrat;
  margin-top: 20px;
}

.collapse__itens {
  display: flex;
  margin-top: 30px;
}

.collapse__itens__cards {
  display: flex;
  justify-self: flex-start;
  max-width: 600px;
  padding-right: 40px;
  width: 100%;
}

.collapse__itens__cards__list {
  display: flex;
  gap: 16px;
  width: 100%;
}

.collapse__itens__cards__list__item {
  border-radius: 5px;
  width: 100%;
  height: 230px;
  position: relative;

  &__image {
    position: absolute;
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-radius: 5px;
  }
}

.collapse__itens__cards__list__item__card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  border-radius: 5px;
  padding: 60px 16px;
  height: 230px;
  width: 100%;
  text-align: center;
  align-items: center;
  position: relative;
}

.collapse__itens__cards__list__item__card__title {
  font: normal normal 900 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
}

.collapse__itens__cards__list__item__card__button {
  padding: 7px 16px !important;
  min-width: 78px !important;
  font: normal normal medium 13px/16px Montserrat;
  letter-spacing: 0px !important;
}

.collapse__itens__options__vagas {
  display: none;
}

.collapse__itens__options {
  display: flex;
  flex-direction: column;
  gap: 95px;
  padding-bottom: 50px;
  margin-left: auto;
}

.collapse__itens__options__list {
  display: grid;
  gap: 40px;
  grid-template-columns: max-content max-content;
}

.collapse__itens__options__list__link {
  // width: 50%;
  font: normal normal normal 20px/24px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  width: auto;
}

.collapse__itens__options__list__link a:hover {
  color: rgb(255, 193, 41);
}

.collapse__itens__options__lang {
  display: flex;
  align-items: center;
  gap: 30px;
}

.collapse__itens__options__lang__option {
  font: normal normal 300 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.5;
  cursor: pointer;
}

.collapse__itens__options__lang__option--selected {
  opacity: 1;
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-color: rgb(255, 193, 41);
  font-weight: 700;
}

.collapse__itens__options__lang__option:hover {
  color: rgb(255, 193, 41);
  opacity: 1;
}

.navbar-wrapper .navbar-custom {
  width: 100% !important;
}

@media screen and (max-width: $mobile) {
  .open_dropdown {
    height: 100%;
    max-height: 100% !important;
  }

  .navbar-custom {
    display: flex;
    align-items: baseline;
  }

  .navbar__container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .row-navbar__uncollapse {
    --bs-gutter-y: 0;
    --bs-gutter-x: 0;
  }

  .row-navbar__collapse {
    transform: translateY(-1000px);
    --bs-gutter-y: 0;
    --bs-gutter-x: 0;
    height: 100%;
    flex-grow: 1;
    margin-bottom: 100px;
  }

  .collapse__itens {
    // padding-left: 20px;
    // padding-bottom: 30px;
    margin: 0px;
    height: 100%;
  }

  .collapse__itens__options {
    width: 100%;
    padding: 0px;
    gap: 15px;
  }

  .collapse__itens__options__list {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 0px;
  }

  .collapse__itens__options__list__link {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .collapse__itens__options__lang {
    // padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .collapse__itens__options__vagas {
    padding: 10px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .collapse__itens__cards {
    display: none;
  }

  .navbar__collapse__text {
    display: none;
  }

  .navbar__content__logo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    left: 50%;
    top: 15px;
    margin: 0 auto;
    transform: translate(-50%, 0);
  }

  .navbar__content__vagas {
    display: none;
  }

  .navbar__content__hamburguer {
    display: flex;
    justify-items: center;
    align-items: center;
    position: absolute;
    right: 20px;
  }
}
</style>
